<template id="rezervari" >
    <div class="rezervari" data-app :key="current_page">
        <div class="content">

            <div class="left-content">
                <div class="header">
                    <div v-for="page in pages" :key="page.page_number" :class="page.completed ? 'steps-active' : 'steps-inactive'"></div>
                </div>
                
                
                <div class="main">                     
                    <img :src="getImgUrl(pages[current_page].src)">
                    <h1>{{pages[current_page].title}}</h1>
                    <h3>{{pages[current_page].description}}</h3>
                </div>
                <div class="footer">
                    <h1>Intrebari?</h1>
                    <h3>Suna la 0754 00 44 00</h3>
                    <h1 v-if="this.AgentLogat">Agent: <span style="color:red;" >{{ NumeAgent }}</span></h1>
                </div>
            </div>
            <!-- alege serviciu -->
            <div class="center-content" v-if="current_page === 0">
                <div class="header">
                    <h1>Alege serviciul</h1>
                </div>
                <div class="main">
                    <div v-for="serviciu of servicii" :key="'serviciu' + serviciu.Id + serviciu.Nume" @click="alege_serviciu(serviciu)"
                    :class="`select-container ${serviciu_selectat.id == serviciu.Id ? 'select-container-active' : '' }`">
                    <div class="img-div">
                        <img :src="serviciu.Iconita" :alt="serviciu.Nume" />
                        </div>
                        <div class="serviciu">
                            <h1>{{serviciu.Nume}}</h1>
                            <h4>{{serviciu.Descriere}}</h4>
                        </div>
                        <!-- <div class="pret">
                            <h1>{{serviciu.Pret}} Lei</h1>
                            <h4>Pret incepand de la</h4>
                        </di>v -->
                    </div>

                </div>
                <div class="footer">
                    <el-button v-if="serviciu_selectat.id != null && Roti.checked" @click="next_page()">
                        <template>
                            Pasul urmator <i class="el-icon-right"></i>
                        </template>
                    </el-button>
                </div>
            </div>

            <!-- alege ora -->
            <div class="center-content" v-if="current_page === 1">
                
                <div class="header">
                    <h1>Alege Data si Ora</h1>
                </div>
                <div class="main-ora">
                    <div class="nav">
                        <el-button @click="previous_month()" icon="el-icon-arrow-left"></el-button>
                       <p>{{luna_selectata.nume}} <span>{{an_selectat}}</span></p>
                       <el-button @click="next_month()" icon="el-icon-arrow-right"></el-button>
                    </div>
                    <div class="container-program">
                        <div class="program" v-loading="loader">
                            <div class="header">LUN</div>
                            <div class="header">MAR</div>
                            <div class="header">MIE</div>
                            <div class="header">JOI</div>
                            <div class="header">VIN</div>
                            <div class="header header-weekend">SAM</div>
                            <div class="header header-weekend">DUM</div>
                        <div  v-for="(value, index) of Program" :key="index+value" class="zi" :style="(value[1].ziua_saptamanii == '0' || value[1].ziua_saptamanii == '6') ? 'background-color: #FCFEF1' : ''">
                            <div href="#ore" @click="select_date(value)"
                            :class="value[1].count_intervale_libere == 0 && value[1].count_intervale_libere == value[1].count_intervale_ocupate ? 'button-zi-indisponibila' : 
                                    value[1].count_intervale_libere > 0 ? value[1].luna_curenta == 1 ? 'button-zi-disponibila' : 'button-zi-disponibila_nu_in_luna_curenta' : 'button-zi-indisponibila'"
                            >{{get_day(value[0])}}
                                <div v-if="value[1].count_intervale_libere > 0 && value[1].intervale" class="bara-liber">
                                    <div v-for="(v, i) of value[1].intervale" :key="'interval' + i" :class="v.liber ? 'liber' : 'ocupat'"></div>
                                </div>
                            </div>
                        </div>


                        </div>
                            <div id="ore" class="ore" v-if="selectedObject.data != undefined">
                                <p><span class="bara">Alege ora programarii pentru <span class="data">{{selectedObject.data}}</span></span></p>
                                <div class="butoane">
                                    <div  v-for="(value, index) of selectedObject.data_object.intervale" :key="'ora' + index" :class="value.liber ? 'liber' : 'ocupat'" @click="select_hour(value)">
                                        {{index}}
                                    </div>
                                </div>
                            </div>
                    </div>  
                </div>
                <div class="footer-ora">
                    <div class="content-footer">
                       <a @click="previous_page()"><i class="el-icon-back"></i> Inapoi</a>
                    </div>
                </div>
            </div>

            <div class="center-content" v-if="current_page === 2">
                <div class="header">
                    <h1>Date de contact</h1>
                </div>
                <div class="main-formular" v-loading="loader">
                    <div v-if="this.AgentLogat" class="">
                        <el-button size="small" type="warning" @click="deschide_dialog_alege_client()">Alege client - masina</el-button>
                    </div>
                    <div v-else class="titlu-formular">
                        <h1 @click="schimba_tip_client(0)" :class="tip_cont.client_nou ? 'selectat' : false">Client Nou</h1>
                        <h1 style="text-align: end" @click="schimba_tip_client(1)" :class="tip_cont.client_vechi ? 'selectat' : ''">Ai mai fost la noi?</h1>
                    </div>
                    <div class="formular">
                        <v-form
                        @submit.native.prevent="save()"
                        lasy-validation
                        class="formular"
                        label-position="top"
                        :inline="false"
                        label-width="100%"
                        :model="selectedObject.Data"
                        :rules="rules"
                        :ref="'my-form'"
                    >
                        <el-row class="rand">
                        <el-col class="coloana">
                            <!-- <el-input v-model="Data.Nume" placeholder="Introdu numele"></el-input> -->
                            <v-text-field
                            dense
                            label="Nume"
                            v-model="selectedObject.Data.Nume"
                            outlined
                            :rules="[rules_vuetify.required, rules_vuetify.nume]"
                            ></v-text-field>
                        </el-col>
                        <el-col class="coloana">
                            <v-text-field
                            dense
                            label="Prenume"
                            v-model="selectedObject.Data.Prenume"
                            outlined
                            :rules="[rules_vuetify.required, rules_vuetify.prenume]"
                            ></v-text-field>
                        </el-col>
                        </el-row>
                        <el-row class="rand">
                        <el-col class="coloana">
                            <v-text-field
                            dense
                            label="Telefon"
                            v-model="selectedObject.Data.Telefon"
                            outlined
                            oninvalid="this.setCustomValidity('Completeaza acest camp')"
                            :rules="[rules_vuetify.required, rules_vuetify.telefon]"
                            ></v-text-field>
                        </el-col>
                        <el-col class="coloana">
                            <v-text-field
                            v-show="!Boolean(NoEmail)"
                            :disabled="Boolean(NoEmail)"
                            dense
                            label="Email"
                            v-model="selectedObject.Data.Email"
                            outlined
                            oninvalid="this.setCustomValidity('Completeaza acest camp')"
                            :rules="[rules_vuetify.email]"
                            ></v-text-field>

                            <v-text-field
                            v-if="Boolean(NoEmail)"
                            onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false"
                            placeholder="noemail@anvelope.ro"
                            dense
                            v-model="selectedObject.Data.NoEmailTastat"
                            outlined
                            oninvalid="this.setCustomValidity('tasteaza noemail@anvelope.ro')"
                            :rules="[rules_vuetify.noemailrule]"
                            ></v-text-field>

                            <v-checkbox 
                            v-if="this.AgentLogat"
                            label="clientul nu are email"
                            v-model="NoEmail"
                            color="red"
                            hide-details
                            value="1"
                            @change="bifa_no_email()"
                            ></v-checkbox>
                            
                            
                        </el-col>
                        </el-row>
                        <el-row class="rand">
                        <el-col class="coloana">
                            <v-text-field
                            dense
                            label="Numar masina"
                            v-model="selectedObject.Data.Numar"
                            @change="format_numar()"
                            hint="Ex: B123ABC"
                            outlined
                            :rules="[rules_vuetify.required, rules_vuetify.numar]"
                            ></v-text-field>
                        </el-col>
                        <el-col class="coloana">
                            <v-text-field
                            pattern="[0-9]*" 
                            inputmode="numeric"
                            dense
                            label="Numar kilometri"
                            v-model="selectedObject.Data.Km"
                            :rules="[rules_vuetify.km]"
                            outlined
                            ></v-text-field>
                        </el-col>
                        </el-row>
                        <el-row class="rand">
                        <el-col class="coloana">
                            <!-- <v-text-field
                            dense
                            label="Marca"
                            v-model="selectedObject.Data.Marca"
                            hint="Ex: Dacia"
                            outlined
                            ></v-text-field> -->
                            <v-autocomplete
                            :items="Marci"
                            :item-text="'Nume'"
                            :item-value="'Id'"
                            label="Marca"
                            v-model="selectedObject.Data.Marca"
                            dense
                            @change="load_model_marca(selectedObject.Data.Marca)"
                            return-object
                            outlined
                            ></v-autocomplete>
                        </el-col>
                        <el-col class="coloana">
                            <v-autocomplete
                            :items="Model_marci"
                            :item-text="'Nume'"
                            :item-value="'Id'"
                            label="Model"
                            v-model="selectedObject.Data.Model"
                            dense
                            :disabled="selectedObject.Data.Marca == '' || selectedObject.Data.Marca == null || selectedObject.Data.Marca.length <= 0 "
                            return-object

                            outlined
                            ></v-autocomplete>
                        </el-col>
                        </el-row>
                        <el-row class="rand">
                        <el-col class="an">
                            <el-date-picker
                                value-format="yyyy"
                                type="year"
                                :picker-options="datePickerOptions"
                                placeholder="Alegeti anul fabricatiei"
                                v-model="selectedObject.Data.An"
                                style="margin-bottom: 10px;"
                            ></el-date-picker>
                        </el-col>
                        </el-row>
                        <el-row  style="width: 100%; display: flex; justify-content: flex-start; padding-top: 22px;font-size: smaller !important;">
                            <v-checkbox
                                v-model="selectedObject.Data.Acord"
                                :rules="[rules_vuetify.required]"
                                style=""
                                >

                                <template v-slot:label>
                                    <div>
                                     &nbsp; Am citit si sunt de acord cu 
                                        <a
                                            target="_blank"
                                            :href="'https://'+url+'/#/politica_de_confidentialitate'"
                                            @click.stop
                                        >
                                            Politica de confidentialitate
                                        </a>
                                    </div>
                                </template>
                            </v-checkbox>
                        </el-row>
                        </v-form>
                        
                    </div>
                </div>
                <!-- :disabled="!Boolean(selectedObject.Data.Acord)" -->
                <div class="footer-formular">
                    <a @click="previous_page()"><i class="el-icon-back"></i> Inapoi</a>
                    <el-button @click="save()" :disabled="programareTrimisa || once" >
                        <template>
                            Finalizeaza programarea <i class="el-icon-right"></i>
                        </template>
                    </el-button>
                </div>
            </div>
            <div class="right-content">
                <div class="header">
                    <h1><span>Sumar</span></h1>
                </div>
                <div class="main">
                    <div class="element" v-for="s of sumar" :key="s.titlu + '_sumar'">
                        <h3>{{s.titlu}}</h3>
                        <h1>{{s.descriere}}</h1>
                    </div>
                </div>
            </div>

        </div>
        <Finalizare ref="finalizare" @close='reload()' @closed="reload()"/>
        <el-dialog 
        :close-on-click-modal="true"
        :close-on-press-escape="true"
        id="roti"
        width="400px"
        :visible.sync="Roti.show_nr_roti"
        :append-to-body="true"
        >
            <div class="roti-container">
                <div class="titlu-roti">
                    <p>{{Roti.TextDialog}}</p>
                </div>
                <hr />
                <div class="continut-roti">
                    <el-form label-position="top">
                        <el-form-item label="Numar roti">
                            <el-input-number :min="1" :max="8" v-model="Roti.nr_roti">
                        
                            </el-input-number>

                        </el-form-item>
                            <div v-if="(typeof Roti.TextButonHotel != 'undefined')">
                                <el-checkbox v-if="Roti.TextButonHotel.length>0" v-model="Roti.hotel">{{Roti.TextButonHotel}}</el-checkbox>
                            </div> 
                    </el-form>
                    <div class="footer-roti">
                            <el-button @click="salveaza_roti()">Continua</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- dialog pentru preselectat masina -->
        <el-dialog title="" :visible.sync="arata_dialog_alege_client" width="70%" append-to-body>
            <div class="roti-container">
                <div class="titlu-roti">
                    <p>Selecteaza masina / clientul pentru programare</p>
                </div>
                <hr />
                <div class="continut-roti">
                    <h6>Cauta dupa numar inmatriculare, nume client sau telefon</h6>      
                    <br />          
                    <el-select size="large" 
                        placeholder="Cauta dupa numar inmatriculare, nume client sau telefon"
                        remote
                        ref="focusShow"
                        :remote-method="handleFindProdus"
                        v-model="IdMasinaSelectataProgramareDirect"
                        filterable>
                            <el-option
                            v-for="item in Info.masini"
                            :key="'lista_masini'+item.Id"
                            :id="'produs_cu_stoc'"
                            :name="'Client: ' + item.Nume + ' ' + item.Prenume + ' Telefon: ' + item.Telefon"
                            :label="'Auto: ' + item.Numar + (item.Marca !== null && item.Marca !== ''? (' - ' + item.Marca) : '') + (item.Model!==null && item.Model !== '' ? (' ' + item.Model): '')"
                            :value="item.Id">
                        </el-option>
                    </el-select>

                    <div class="footer-roti" style="justify-content: flex-end;">
                        <a @click="arata_dialog_alege_client=!arata_dialog_alege_client">Renunta</a>
                        <el-button size="small" v-show="this.IdMasinaSelectataProgramareDirect != ''" type="success" @click="alege_clientul()" >Alege</el-button>
                    </div>

                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import moment from 'moment';

import Finalizare from './Finalizare.vue';
import settings from '../backend/LocalSettings.js';

    export default {
        
        name: "Home",
        components: {
            Finalizare
        },
        data() {
            return {
                programareTrimisa: false,
                once:false,
                Info: {
                    masini:[],
                },
                arata_dialog_alege_client: false,
                IdMasinaSelectataProgramareDirect: '',
                url: window.location.hostname,
                t: 0,
                NumeAgent:'',
                AgentLogat: 0,
                NoEmail: 0,
                datePickerOptions: {
                    disabledDate(date) {
                    return date > new Date();
                    },
                },
                current_page: 0,
                loader: false,
                pages: [
                    {
                        completed: false,
                        page_number: 0,
                        title: 'Programare Service',
                        description: "  ",
                        src: "page1.png"
                    },
                    {
                        completed: false,
                        page_number: 1,
                        title: 'Alege Data si Ora',
                        description: "",
                        src: "page2.png"
                    },
                    {
                        completed: false,
                        page_number: 2,
                        title: 'Lasa-ne datele tale de contact',
                        description: "",
                        src: "page3.png"
                    },
                ],
                servicii: [],
                sumar: [
                    /**
                     * TEMPLATE SUMAR
                     {
                        titlu: 'titlu',
                        descriere: 'descriere'
                     }
                     */
                ],
                serviciu_selectat: {
                    centru: null,
                    id: null,
                    nume: null,
                    pret: null
                },
                tip_cont: {
                    client_nou: true,
                    client_vechi: false,
                },
                Marci: [],
                Model_marci: [],
                Program: {},
                selectedObject: {
                    data: undefined,
                    data_object: undefined,
                    serviciu: '',
                    Data: {
                        Nume: '',
                        Prenume: '',
                        Telefon: '',
                        Email: '',
                        NoEmailTastat: '',
                        Numar: '',
                        Km: '',
                        Marca: '',
                        Model: '',
                        An: '',
                        Ora: undefined,
                        Ora_start: undefined,
                        Ora_stop: undefined,
                        Acord: Boolean(true),
                    },
                },
                luna_selectata: {
                    numar: '',
                    nume: ''
                },
                Roti: {
                    checked: false,
                    TextDialog: '',
                    show_nr_roti: false,
                    hotel: false,
                    nr_roti: 4
                },
                an_selectat: "",
                rules_vuetify: {
                    nume: (valid) => {
                        this.selectedObject.Data.Nume = valid.toUpperCase();
                        return true;
                    },
                    prenume: (valid) => {
                        this.selectedObject.Data.Prenume = valid.toUpperCase();
                        return true;
                    },
                    required: (valid) => !!valid || "Camp obligatoriu",
                    telefon: (valid) => {
                        this.selectedObject.Data.Telefon = valid.split("").filter(a => /^\d+$/.test(a)).join("");
                        // let isnum = /^\d+$/.test(valid);
                        // if (!isnum) {
                        //     return 'Numar de telefon invalid'
                        // } else {
                        //     return true;
                        // }
                        return true;
                    },
                    email: (valid) => {
                        this.selectedObject.Data.Email = valid.toLowerCase();
                        let email_validation = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(valid);
                        if (this.AgentLogat==1 && valid == 'noemail@anvelope.ro' && this.NoEmail==false) {
                            // this.selectedObject.Data.Email = '';
                            return "Daca clientul nu are email atunci trebuie bifata optiunea de mai jos"
                        } else if (this.AgentLogat==1 && valid.length == 0 && this.NoEmail==false) {
                            return "Camp obligatoriu!"
                        } else if (this.AgentLogat==1 && !email_validation) {
                            return "Format email invalid (ex: nume@gmail.com)" 
                        } else if (this.AgentLogat==0 && !email_validation && valid.length > 0 ) {
                            return "Format email invalid (ex: nume@gmail.com)"
                        } else {
                            return true;
                        }
                    }, 
                    noemailrule: (valid) => {

                        if(typeof valid != 'undefined') valid = valid.toLowerCase().trim();

                        let no_reply_address = valid;

                        if (no_reply_address != 'noemail@anvelope.ro') {
                            this.selectedObject.Data.Email = '';
                            return "in acest camp trebuie tastat 'noemail@anvelope.ro'"
                        } else {
                            this.selectedObject.Data.Email = 'noemail@anvelope.ro';
                            return true;
                        }
                    },
                    km: (valid) => {
                        this.selectedObject.Data.Km = valid.split("").filter(a => /^\d+$/.test(a)).join("");
                        return true; 
                    },
                    numar: (valid) => {
                        this.selectedObject.Data.Numar = valid.toUpperCase();
                        if (valid.length < 3) return 'Numarul trebuie sa aiba cel putin 3 caractere';
                        let regex = /(([0-9]+[A-Z]+)|([A-Z]+[0-9]+))/
                        if (!regex.test(valid)) {
                            return 'Numarul trebuie sa aiba cel putin o litera si o cifra';
                        } else {
                            return true;
                        }
                    }
                },
                rules: {
                    Nume: [{ required: true, message: "Campul este obligatoriu" }],
                    Prenume: [{ required: true, message: "Campul este obligatoriu" }],
                    Numar: [{ required: true, message: "Campul este obligatoriu" }],
                    Telefon: [{ required: true, message: "Campul este obligatoriu" }],
                    Ora: [{ required: true, message: "Campul este obligatoriu" }],
                },
            }
        },
        methods: {

            deschide_dialog_alege_client() {
                this.arata_dialog_alege_client = true;
                this.Info.masini = [];
                this.IdMasinaSelectataProgramareDirect = '';
              
                this.$nextTick(() => {
                    this.$refs.focusShow.focus();
                });

            },
            async  handleFindProdus(data) {
                if (data !== '') {
                    await new Promise(resolve => setTimeout(resolve, 200)); 

                    let url = 'https://service.anvelopeinstoc.ro/api/index.php/test_api/remoteFindProdus';
                    let args = {CheckSender:settings.get_key(), string: data}
                    let result = await this.$http.post(url, args);

                    if(result){
                        this.Info.masini = result.body.masini;
                    }
                }
            },

            bifa_no_email: function() {
                if(!this.NoEmail){
                    this.selectedObject.Data.Email = '';
                }
            },

            format_numar: function() {
                this.selectedObject.Data.Numar = this.selectedObject.Data.Numar.replaceAll(' ', '');
                this.selectedObject.Data.Numar = this.selectedObject.Data.Numar.toUpperCase();
            },
            
            arata_bifa_hotel: async function() {
                if(typeof this.Roti.TextButonHotel != 'undefined')
                    if(this.Roti.TextButonHotel.length>0) {
                        console.log(true)
                        return true
                    }
                return false;
            },

            reload: function() {
                this.$router.go(0);
            },

            alege_serviciu: async function(serviciu) {
                // console.log(serviciu);
                this.serviciu_selectat = {
                    centru: serviciu.IdCentruProductie,
                    id: serviciu.Id,
                    nume: serviciu.Nume,
                    // pret: serviciu.Pret
                };
                this.sumar[0] = {
                    titlu: 'SERVICIU',
                    descriere: serviciu.Nume
                }
                this.Roti.TextDialog = serviciu.TextDialog
                this.Roti.TextButonHotel = serviciu.TextButonHotel
                if(this.Roti.TextDialog.length == 0){
                    this.Roti.nr_roti = 1;
                    this.salveaza_roti();
                } else {
                    this.Roti.nr_roti = 4;
                    this.Roti.show_nr_roti = true;
                }


            },

            schimba_tip_client: function(type) {
                if (type == 0 && this.tip_cont.client_nou) return;
                if (type == 1 && this.tip_cont.client_vechi) return; 
                this.loader = true;
                this.tip_cont.client_nou = !this.tip_cont.client_nou;
                this.tip_cont.client_vechi = !this.tip_cont.client_vechi;
                if (this.tip_cont.client_vechi) {
                    if (localStorage.getItem('evio-service-account')) {
                        let data = JSON.parse(localStorage.getItem('evio-service-account'));
                        this.selectedObject.Data.Nume = data.Nume;
                        this.selectedObject.Data.Prenume = data.Prenume;
                        this.selectedObject.Data.Telefon = data.Telefon;
                        this.selectedObject.Data.Email = data.Email;
                        this.selectedObject.Data.Marca = data.Marca;
                        this.selectedObject.Data.Model = data.Model;
                        this.selectedObject.Data.An = data.An;
                        this.selectedObject.Data.Km = data.Km;
                        this.selectedObject.Data.Numar = data.Numar;
                    }
                } else {
                    this.selectedObject.Data.Nume = '';
                    this.selectedObject.Data.Prenume = '';
                    this.selectedObject.Data.Telefon = '';
                    this.selectedObject.Data.Email = '';
                    this.selectedObject.Data.Marca = '';
                    this.selectedObject.Data.Model = '';
                    this.selectedObject.Data.Numar = '';
                    this.selectedObject.Data.An = '';
                    this.selectedObject.Data.Km = '';

                }
                this.loader = false;
            },

            alege_clientul: async function(type) {

                this.arata_dialog_alege_client = false;
                this.loader = true;

                let date_auto = this.Info.masini.filter((value) => value.Id == this.IdMasinaSelectataProgramareDirect)[0];
                
                this.selectedObject.Data.Nume = date_auto.Nume;
                this.selectedObject.Data.Prenume = date_auto.Prenume;
                this.selectedObject.Data.Telefon = date_auto.Telefon;
                this.selectedObject.Data.Email = date_auto.Email;
                this.selectedObject.Data.An = date_auto.An;
                this.selectedObject.Data.Numar = date_auto.Numar;
                this.selectedObject.Data.Marca = '';
                this.selectedObject.Data.Model = '';

                if(typeof date_auto.Marca == 'string'){
                    this.selectedObject.Data.ObiectMarca = this.Marci.filter((value) => value.Nume == date_auto.Marca.toUpperCase())[0];

                    if(typeof this.selectedObject.Data.ObiectMarca !== 'undefined' &&  this.selectedObject.Data.ObiectMarca.Id > 0){
                        this.selectedObject.Data.Marca = this.selectedObject.Data.ObiectMarca.Id;
                        await this.load_model_marca(this.selectedObject.Data.ObiectMarca);
                        if(typeof date_auto.Model == 'string'){
                            this.selectedObject.Data.ObiectModel = this.Model_marci.filter((value) => value.Nume == date_auto.Model.toUpperCase())[0];
                            if(typeof this.selectedObject.Data.ObiectModel.Nume == 'string' && this.selectedObject.Data.ObiectModel.Id > 0){
                                this.selectedObject.Data.Model = this.selectedObject.Data.ObiectModel.Id;
                            } else {
                                this.selectedObject.Data.Model = "";
                            }
                        }

                    } else {
                        this.selectedObject.Data.IdMarca = "";
                    }
                }

                this.loader = false;

            },

            load_marci: async function() {
                let url = 'https://service.anvelopeinstoc.ro/api/index.php/test_api/get_marci';
                let args = {CheckSender:settings.get_key()}
                let response = await this.$http.post(url, args);
                let most_used = [
                    "audi",
                    "bmw",
                    "dacia",
                    "ford",
                    "renault",
                    "mercedes-benz",
                    "opel",
                    "skoda",
                    "volkswagen"
                ]

                var most_used_list = [];
                var others = [];

                response.body.raspuns.map(r => {
                    return {
                        Id: r.Id,
                        Nume: r.Nume.toUpperCase()
                    }
                }).forEach(marca => {
                    if (most_used.includes(marca.Nume.toLowerCase())) {
                        // console.log('Am gasit', marca.Nume);
                        most_used_list.push(marca);
                    } else {
                        others.push(marca);
                    }
                });

                this.Marci = [{header: "Marci populare"},...most_used_list, {divider: true}, ...others];
                // console.log(this.Marci)

            },

            

            load_model_marca: async function(Marca) {
                this.selectedObject.Data.Model = "";
                // console.log(Marca)
                let url = 'https://service.anvelopeinstoc.ro/api/index.php/test_api/get_tipuri_marca';
                let args = {IdMarca: Marca.Id, CheckSender:settings.get_key()}
                let response = await this.$http.post(url, args);
                // console.log(response);
                this.Model_marci = response.body.raspuns.map(r => {
                    return  {
                        Id: r.Id,
                        Nume: r.Nume.toUpperCase(),
                        IdMarca: r.IdMarca
                    }
                });
                // console.log(this.Model_marci);
            },


            save: async function() {

                let valid = this.$refs["my-form"].validate()
                if (valid) {
                    this.programareTrimisa = true;
                    this.once = true;
                    if (localStorage.getItem('evio-service-account')) {
                        localStorage.removeItem('evio-service-account');
                        localStorage.setItem('evio-service-account', JSON.stringify(this.selectedObject.Data));
                    } else {
                        localStorage.setItem('evio-service-account', JSON.stringify(this.selectedObject.Data));
                    }
                    let args = { data: this.selectedObject.Data, idServiciuProgramator: this.serviciu_selectat.id , CheckSender:settings.get_key(), Sursa: settings.get_local_url()}
                    let api_url = "https://service.anvelopeinstoc.ro/api/index.php/test_api/adauga_programare";
                    const temp_marca = this.selectedObject.Data.Marca;
                    const temp_model = this.selectedObject.Data.Model;
                    
                    this.selectedObject.Data.NumeAgent = this.NumeAgent;
                    this.selectedObject.Data.Marca = this.selectedObject.Data.Marca.Nume || "";
                    this.selectedObject.Data.Model = this.selectedObject.Data.Model.Nume || "";
                    // console.log(this.selectedObject.Data)
                    this.selectedObject.Data.Cantitate = this.Roti.nr_roti;
                    this.selectedObject.Data.Hotel = this.Roti.hotel;
                    let res = await this.$http.post(api_url, args);
                    this.once = false;
                    this.selectedObject.Data.Marca = temp_marca;
                    this.selectedObject.Data.Model = temp_model;
                    if (res.body.success == true) {
                        let idProgramare = res.body.IdProgramare;
                        this.$refs.finalizare.show_me(this.serviciu_selectat, this.selectedObject.Data, idProgramare);
                        await this.$message({
                            type: "success",
                            message: "Programare efectuata cu succes",
                        });
                    } else {
                        this.$message({
                            type: "warning",
                            message: "Programarea nu a putut fi efectuata. (" + res.body.error + ')',
                        });
                        this.programareTrimisa = false;
                        this.schimba_tip_client(0);
                        if(typeof res.refresh){
                            this.load_program(this.serviciu_selectat.id);
                        }
                        this.previous_page();
                    }
                }
            },

            select_date: async function(value) {
                if (value[1].count_intervale_libere == 0) return;
                if (value[1].count_intervale_libere == value[1].count_intervale_ocupate && value[1].count_intervale_libere == 0) return;
                let cantitate = this.Roti.nr_roti;
                let hotel = this.Roti.hotel
                let api_url =
                    "https://service.anvelopeinstoc.ro/api/index.php/test_api/index_zi_php"; // de pus la loc /api/index.php/test_api/index_zi
                let args = {zi: value[0], idServiciuProgramator: this.serviciu_selectat.id, Cantitate: cantitate, Hotel: hotel, CheckSender:settings.get_key()}
                let response = await this.$http.post(api_url, args);
                this.selectedObject.data = `${this.get_day(value[0])} ${this.get_nume_luna(moment(value[0]).format('MM'))}`;
                this.selectedObject.data_object = response.data;
                this.sumar[1] = {
                    titlu: 'DATA',
                    descriere: this.selectedObject.data
                };
                setTimeout(() => {
                    document.getElementById('ore').scrollIntoView();
                }, 10)
            },

            select_hour: function(value) {
                if (!value.liber) return;
                this.selectedObject.Data.Ora = value.start;
                this.selectedObject.Data.Ora_start = value.start;
                this.selectedObject.Data.Ora_stop = value.stop;
                this.sumar[2] = {
                    titlu: 'ORA',
                    descriere: moment(value.start).format('HH:mm'),
                }
                this.selectedObject.Data.Acord = true;
                this.next_page();
            },

            next_page: async function() {
                this.pages[this.current_page].completed = true;
                this.current_page++;
            },

            previous_page: async function() {
                this.current_page--;
                this.pages[this.current_page].completed = false;
            },

            getImgUrl(page) {
                return require("../assets/"+page)
            },

            get_day: function(date) {
                let day = moment(date).format('D');
                return day;
            },

            clear_program: function() {
                this.Program = {};
            },

            previous_month: async function() {
                this.loader = true;
                let previous_month = moment(this.luna_selectata.numar).add(-1, 'M').startOf('month').format('YYYY-MM-DD hh:mm:ss');
                let cantitate = this.Roti.nr_roti;
                let hotel = this.Roti.hotel
                let dateClient = {};
                if(this.AgentLogat != 1){
                    dateClient = localStorage.getItem('evio-service-account');
                }
                let args = {
                    start: previous_month,
                    idServiciuProgramator: this.serviciu_selectat.id, 
                    Cantitate: cantitate, 
                    Hotel: hotel, 
                    CheckSender:settings.get_key(), 
                    date_client: dateClient
                }
                
                let url = 'https://service.anvelopeinstoc.ro/api/index.php/test_api/index_php';
                let response = await this.$http.post(url, args);

                this.clear_program();

                this.Program = Object.entries(response.data.zile);
                this.luna_selectata.numar = moment(previous_month).format('YYYY-MM-DD hh:mm:ss');
                this.luna_selectata.nume = this.get_nume_luna(moment(previous_month).format( 'MM'));
                this.an_selectat = moment(previous_month).format('YYYY');
                this.loader = false;
             },

            next_month: async function() {
                this.loader = true;
                let next_month = moment(this.luna_selectata.numar).add(1, 'M').startOf('month').format('YYYY-MM-DD hh:mm:ss');
                let cantitate = this.Roti.nr_roti;
                let hotel = this.Roti.hotel;
                let dateClient = {};
                if(this.AgentLogat != 1){
                    dateClient = localStorage.getItem('evio-service-account');
                }
                let args = {
                    start: next_month,
                    idServiciuProgramator: this.serviciu_selectat.id, 
                    Cantitate: cantitate, 
                    Hotel: hotel,CheckSender:settings.get_key(), 
                    date_client: dateClient
                };
                let url = 'https://service.anvelopeinstoc.ro/api/index.php/test_api/index_php';
                let response = await this.$http.post(url, args);

                this.clear_program();

                this.Program = Object.entries(response.data.zile);
                this.luna_selectata.numar = moment(next_month).format('YYYY-MM-DD hh:mm:ss');
                this.luna_selectata.nume = this.get_nume_luna(moment(next_month).format('MM'));
                this.an_selectat = moment(next_month).format('YYYY');
                this.loader = false;

            },

            get_nume_luna: function(luna) {
                var nume = '';
                      switch (luna) {
                        case "01":
                        nume = "Ianuarie";
                        break;

                        case "02":
                        nume = "Februarie";
                        break;

                        case "03":
                        nume = "Martie";
                        break;

                        case "04":
                        nume = "Aprilie";
                        break;

                        case "05":
                        nume = "Mai";
                        break;

                        case "06":
                        nume = "Iunie";
                        break;

                        case "07":
                        nume = "Iulie";
                        break;

                        case "08":
                        nume = "August";
                        break;

                        case "09":
                        nume = "Septembrie";
                        break;

                        case "10":
                        nume = "Octombrie";
                        break;

                        case "11":
                        nume = "Noiembrie";
                        break;

                        case "12":
                        nume = "Decembrie";
                        break;
                    }
                    return nume;   
            },

            salveaza_roti() {

                if(this.Roti.TextButonHotel.length == 0) this.Roti.hotel = false;
                this.Roti.show_nr_roti = false;
                this.Roti.checked = true;
                this.load_program(this.serviciu_selectat.id);
                this.next_page();
            },


            load_program: async function(id) {
                this.loader = true;
                this.selectedObject = {
                    data: undefined,
                    data_object: undefined,
                    serviciu: '',
                    Data: {
                        Nume: '',
                        Prenume: '',
                        Telefon: '',
                        Email: '',
                        Km: '',
                        Numar: '',
                        Marca: '',
                        Model: '',
                        An: '',
                        Ora: undefined
                    }
                }
                let cantitate = this.Roti.nr_roti;
                let hotel = this.Roti.hotel;
                // let url = 'https://service.anvelopeinstoc.ro/api/index.php/test_api/index';
                let url = 'https://service.anvelopeinstoc.ro/api/index.php/test_api/index_php';// de pus la loc

                let dateClient = {};
                if(this.AgentLogat != 1){
                    dateClient = localStorage.getItem('evio-service-account');
                }
                let args = {idServiciuProgramator: id, Cantitate: cantitate, Hotel: hotel, CheckSender:settings.get_key(), date_client: dateClient}
                let response = await this.$http.post(url, args);
                this.Program = Object.entries(response.data.zile);
            
                this.luna_selectata.numar = moment().format('YYYY-MM-DD hh:mm:ss');
                this.luna_selectata.nume = this.get_nume_luna(moment().format('MM'));
                this.an_selectat = moment().format('YYYY'); 
                // console.log(this.Program);
                this.loader = false;
            },

            load_servicii: async function() {
                let url = 'https://service.anvelopeinstoc.ro/api/index.php/test_api/get_centre_productie'; // de pus la loc
                let args = {CheckSender:settings.get_key()}
                let response = await this.$http.post(url, args);
                this.pages[0].title = response.body.Setari.TitleProgramator1;
                this.pages[1].title = response.body.Setari.TitleProgramator2;
                this.pages[2].title = response.body.Setari.TitleProgramator3;

                this.pages[0].description = response.body.Setari.SubtitleProgramator1;
                this.pages[1].description = response.body.Setari.SubtitleProgramator2;
                this.pages[2].description = response.body.Setari.SubtitleProgramator3;

                let servicii = response.body.raspuns;
                // console.log(servicii);
                // console.log(servicii);
                servicii.forEach(serviciu => {
                    let data = serviciu;
                    this.servicii.push(data);
                });
            },
 
        },
        mounted() {
            this.load_servicii();
            this.load_marci();

            if(settings.get_numeAgent() != null){
                console.log('e logat')
                console.log(typeof settings.get_numeAgent())
                console.log( settings.get_numeAgent())
                this.NumeAgent = settings.get_numeAgent();
                this.AgentLogat = 1;
            }
        }
    }
</script>

<style lang="less">
    @gri: #F1F2F2;
    @gri_inchis: #8e97b3;
    @albastru_transparent:rgba(76, 100, 232, 0.445);
    @albastru: #4C63E8;
    @verde: #4C63E8;
    @rosu-hover: #F7E8E8;
    @rosu: #ec7376;
    @rosu_deschis: #F7E8E8;
    @galben: #FEF6CB;
    @galben_wallpaper: #FCFEF1;
    @galben_inchis: #E1D588;
    @liber: #caffc4;
    @liber_text: #185210;
    @ocupat: #f3f5f9;
    @ocupat_text: #b3bbca;
    @zi_indisponibila: #f7e9e9;
    @zi_indisponibila_text: #752f2f;


    #produs_cu_stoc:after {
  content: attr(name);
  width: auto;
  max-height: 27px !important;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  font-size: 12px !important;
  padding: 0 5px;
  margin: 2px 0;
  border: 1px solid transparent;
  border-radius: 7px;
  background-color: #d6dee5a1;
}

    

    .v-input--selection-controls {
     margin-top: -12px !important; 
     margin-bottom: 11px !important; 
     padding-top: 0px !important; 
    } 
    
    .el-dialog__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;

    }
    .el-dialog {
        margin-top: 0 !important;
        height: 300px;
    }


    .roti-container  {
        width: 100%;
        height: 100%;
        .titlu-roti {
            width: 100%;
            // border: 1px solid red;
            height: 50px;
            display: flex;
            padding: 20px;
            padding-bottom: 0;
            justify-content: flex-start;
            align-items: center;
            font-size: 14px;
            margin: 0;
            font-weight: 700;
            p {
                color: @albastru;
                padding: 0;
                margin: 0;
            }
        }
        .continut-roti {
            width: 100%;
            // height: 100%;
            padding: 0 20px;
            width: 100%;
            height: 210px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .el-form {
                display: flex;
                justify-content: space-evenly;
                // align-items: center;
                flex-direction: column;
            }
            .footer-roti {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .el-button {
                    margin: 20px 0 0;
                    background-color: @albastru;
                    color: white;
                }
                a {
                color: @albastru;
                font-weight: 600;
                margin: 20px 15px 0 0;
                padding: 0;
                text-align: center;
                cursor: pointer;
            }
            }
        }

    }

    .error--text {
        color: #ff5252 !important;
        caret-color: #ff5252 !important;
    }

    .v-application .error--text {
        color: #ff5252 !important;
        caret-color: #ff5252 !important;
    }

    

    .rezervari {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }


    .content {
        width: 60%;
        height: 63%;
        display: flex;
        align-items: center;
        flex-direction: row;
        border-radius: 5px;
        box-shadow: 0px 2px 4px #00000033, 0px 20px 50px #00000066;
    }




    .left-content, .right-content, .center-content {
        height: 100%;
    }

    .left-content {
        background-color: #fff;
        width: 22%;
        .header {
            width: 100%;
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: center;

            .steps-active, .steps-inactive {
                margin: 0 0.5rem;
            }
            .steps-active {
                width: 10px;
                height: 10px;
                background-color: @albastru;
                border-radius: 2px;
            }

            .steps-inactive {
                width: 10px;
                height: 10px;
                background-color: @gri;
                border-radius: 2px;
            }
        }

        .main {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 70%;
            flex-direction: column;
            font-weight: 800;
            animation: fadeIn 1s;
            h1 {
                font-size: 18px;
                text-align: center;
                color: @albastru;
            }

            h3 {
                font-size: 14px;
                color: @gri_inchis;
            }
        }

        .footer {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20%;
            flex-direction: column;
            font-weight: 800;

            h1 {
                font-size: 14px;
                color: @albastru;
            }

            h3 {
                font-size: 12px;
                color: @gri_inchis;
            }
        }
    }
    .center-content {
        border: 1px solid @gri;
        border-top: none;
        border-bottom: none;
        width: 50%;
        animation: fadeIn 1s;
        .header {
            width: 90%;
            height: 8%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            h1 {
                font-size: 18px;
                color: @albastru;
                margin-bottom: 0;
            }
        }

        .main {
            width: 100%;
            height: 80%;
            border-top: 1px solid @gri;
            border-bottom: 1px solid @gri;
            display: flex;
            overflow: auto;
            align-items: center;
            flex-direction: column;



            .select-container {
                
                border: 2px solid #E5E7EE !important;
                border-radius: 5px;
                width: 90%;
                margin: 20px 0;
                height: 130px;
                cursor: pointer;
                box-shadow: none;
                transition: all .3s ease;
                display: flex;
                justify-content: space-between;
                align-items: center;
                animation: FromTop 0.45s;
                .img-div {
                    width: 100px;
                    height: 100px;
                    margin: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center ;
                    img {
                        max-width:100%;
                        max-height:100%;
                    }
                }
                .serviciu {
                    transition: 0.4s;
                    width: 80%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;
                    text-align: start;
                    padding: 0 10px;
                    h1 {
                        font-size: 18px;
                        color: @albastru

                    }
                    h4 {
                        font-size: 14px;
                    }
                }
                .pret {
                    width: 30%;
                    height: 85%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    border-left: 1px solid @gri;
                    padding: 0 10px;
                    text-align: center;
                    h1 {
                        color: @albastru;
                        font-size: 20px;
                    }

                    h4 {
                        color: @gri_inchis;
                        font-size: 14px;
                    }
                }

            }
            
            .select-container:hover {
                border: 2px solid #4c63e8 !important;
                .serviciu {
                    margin-left: 10px;
                }

            }

            .select-container-active {
                border: 3px solid @albastru !important;
                box-shadow: 5px 10px 0 @albastru_transparent;

            }

            
        }

        .footer {
            width: 100%;
            height: 12%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .el-button {
                margin: 0 20px;
                background-color: @albastru;
                color: white;
            }
        }

        .footer-ora {
            margin: 0 10px;
            width: 100%;
            height: 5%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .content-footer {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                padding: 0;
                a {
                color: @albastru;
                font-weight: 600;
                margin: 0;
                padding: 0;
                text-align: center;
                cursor: pointer;
            }
            }
        }
        
        .main-ora {
            box-sizing: border-box;
            width: 100%;
            height: 87%;
            overflow: auto;
            border-top: 1px solid @gri;
            border-bottom: 1px solid @gri;
            display: flex;
            align-items: center;
            flex-direction: column;
            scroll-behavior: smooth !important;
            
            .nav {
                width: 90%;
                height: 60px;
                margin: 20px 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                p {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 0;

                    span {
                        color: @gri_inchis;
                    }
                }
                .el-button {
                    border: none;
                    font-weight: 800;
                }
            }

                .container-program {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                }
            

                .ore {
                    box-sizing: border-box;
                    margin: 3% 0;
                    width: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    p {
                        font-size: 16px;
                        width: 100%; 
                        text-align: center; 
                        border-bottom: 1px solid @gri; 
                        // line-height: 0.1em;
                        margin: 10px 0 20px; 
                    }
                    p .bara { 
                            background:#fff; 
                            padding:0 10px; 
                    }

                    p .data {
                        color: @albastru;
                        font-weight: 600;
                    }

                    .butoane {
                        font-size: 14px;
                        margin: 0 44px;
                        max-width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        flex-wrap: wrap;

                        .liber {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: @liber;
                            color: @liber_text;
                            margin: 3px;
                            padding: 8px 3px;
                            width: 83px;
                            height: 31px;
                            border-radius: 5px;
                        }

                        .ocupat {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: @ocupat;
                            color: @ocupat_text;
                            margin: 3px;
                            padding: 8px 3px;
                            width: 83px;
                            height: 31px;
                            border-radius: 5px;
                            cursor: not-allowed;
                        }

                        .liber:hover {
                            border: 1px solid #6bd25b;
                            cursor: pointer;
                        }

                        .button-ora {
                            margin: 3px;
                            padding: 8px 3px;
                            width: 83px;
                            height: 31px;
                            border-radius: 5px;
                        }
                    }
                }

            .program {
                    animation: ToTopOrar 0.45s;
                    margin: 0 auto;
                    padding: 0;
                    width: 100% !important;
                    box-sizing: border-box;
                    display: grid;
                    justify-content: center;
                    grid-template-columns: repeat(7, 12.5%);
                    grid-column-gap: 0px;
                    grid-row-gap: 0px;
                    .header {
                        margin: 0;
                        width: 100%;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: @albastru;
                        font-size: 14px;
                        font-weight: 700;
                    }
                    .header-weekend {
                        color: @galben_inchis;
                        background-color: @galben_wallpaper;
                    }

                    .zi {
                        margin: 0;
                        width: 100%;
                        height: 65px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                        font-size: 14px;
                        .button-zi-disponibila {
                            margin: 0;
                            width: 45px;
                            height: 45px;
                            text-align: center;
                            padding: 0;
                            border-radius: 6px;
                            background-color: @gri;
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            transition: 0.1s;
                            .bara-liber {
                                overflow: hidden;
                                width: 75%;
                                height: 6px;
                                border-radius: 3px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                flex-direction: row;
                                background-color: #e6e9ef;
                                
                                .liber {
                                    width: 100%;
                                    height: 100%;
                                    margin: 0;
                                    padding: 0;
                                    background-color: #8fe286;
                                }

                                .ocupat {
                                    width: 100%;
                                    height: 100%;
                                    margin: 0;
                                    padding: 0;
                                    background-color: transparent;
                                }
                            }
                        }


                        .button-zi-disponibila:hover {
                            font-size: 18px;
                        }

                        .button-zi-disponibila_nu_in_luna_curenta {
                            opacity: 0.5;
                            margin: 0;
                            width: 45px;
                            height: 45px;
                            text-align: center;
                            padding: 0;
                            border-radius: 6px;
                            background-color: #efefefbb;
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            transition: 0.1s;
                            .bara-liber {
                                overflow: hidden;
                                width: 75%;
                                height: 6px;
                                border-radius: 3px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                flex-direction: row;
                                background-color: #e6e9ef;
                                
                                .liber {
                                    width: 100%;
                                    height: 100%;
                                    margin: 0;
                                    padding: 0;
                                    background-color: #8fe286;
                                }

                                .ocupat {
                                    width: 100%;
                                    height: 100%;
                                    margin: 0;
                                    padding: 0;
                                    background-color: transparent;
                                }
                            }
                        }


                        .button-zi-disponibila_nu_in_luna_curenta:hover {
                            font-size: 18px;
                            opacity: 1;
                        }

                        .button-zi-indisponibila {
                            margin: 0;
                            width: 45px;
                            height: 45px;
                            text-align: center;
                            padding: 0;
                            border-radius: 6px;
                            background-color: @zi_indisponibila;
                            color: @zi_indisponibila_text;
                            cursor: not-allowed;
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                            opacity: 0.5;
                        }

                        .button-zi-plina {
                            margin: 0;
                            width: 55px;
                            height: 55px;
                            text-align: center;
                            padding: 0;
                            border-radius: 6px;
                            background-color: @zi_indisponibila;
                            color: @zi_indisponibila_text;
                            cursor: not-allowed;
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                        }
                    }

            }


            .weeks {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin: 0;
                padding: 10px 0;

                .el-button {
                    margin: 10px 0;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    padding: 0;
                    span {
                        font-weight: 800 !important;
                    }

                }
            }
        }

        .main-formular {
            width: 100%;
            height: 82%;
            overflow: auto;
            padding: 1% 5%;
            border-top: 1px solid @gri;
            border-bottom: 1px solid @gri;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            .titlu-formular {
                width: 100%;
               margin: 20px 0;
               display: flex;
               justify-content: space-between;
               text-align: start;
               height: 50px;
               h1 {
                font-weight: 400;
                color: @gri_inchis;
                font-size: 14px;
                width: 30%;
                height: 100%;
                cursor: pointer;
               }

               h2 {
                font-weight: 400;
                color: @albastru;
                font-size: 14px;
                width: 30%;
                height: 100%;
                cursor: pointer;
               }
            .selectat {
                border-bottom: 2px solid @albastru;
                font-size: 16px;
                animation: grow .269s;
                color: black;
                font-weight: 500;
               }
               border-bottom: 1px solid @gri_inchis;
            }

            .formular {
                width: 100%;
                margin: 15px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .rand {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                }
                .coloana {
                    width: 49%;
                    margin: 0 1%;
                }

                .an {
                    width: 49%;
                    margin: 0 1%;
                    .el-date-editor.el-input {
                        width: 100%;
                    }
                }

                .an:first-child, .coloana:first-child {
                    margin-left: 0;
                }

                .an:last-child, .coloana:last-child {
                    margin-right: 0;
                }
            }
            
        }
        
        .footer-formular {
            width: 100%;
            min-height: 10%;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            a {
                color: @albastru;
                font-weight: 600;
                margin: 5px 20px;

            }

            a:hover {
                color: @albastru;
                cursor: pointer;
            }
            .el-button {
                border: none;
                margin: 5px 20px;
                background-color: @albastru;
                color: white;
            }
            
        }

    }


    .program_plin {
        background-color: @rosu_deschis;
        border-color: @rosu_deschis;
        color: rgba(0, 0, 0, 0.311);
    }
    .right-content {
        background-color: #fff;
        width: 28%;
        .header {
            display: flex;
            height: 10%;
            justify-content: flex-start;
            padding: 0 20px;
            align-items: center;
            h1 {
                font-size: 18px;
                color: @albastru;
                width: 100%; 
                text-align: end; 
                border-bottom: 2.5px dotted @albastru;
                border-top: 2.5px dotted @albastru;
                line-height: 0.1em;
                margin: 10px 0; 
            }
            h1 span { 
                    background:#fff; 
                    padding:0 10px;
                    margin: 0 30px 
            }
        }

        .main {
            width: 100%;
            height: 80%;
            border-top: 1px solid @gri;
            border-bottom: 1px solid @gri;
            padding: 0 15px;
            display: grid;
            justify-content: center;
            grid-template-columns: repeat(2, 45%);
            grid-template-rows: repeat(3, 60px);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            justify-content: flex-start;
            flex-wrap: wrap;
            flex-direction: column;
            .element {
                width: 100%;
                height: 80px;
                text-align: left;
                animation: ToTopOrar 0.2s;
                padding: 15px 0;
                border-bottom: 1px solid @gri;
                h3 {
                    font-size: 10px;
                    color: @gri_inchis;
                    font-weight: 600;
                    margin: 0;
                }
                h1 {
                    font-size: 14px;
                    color: @albastru;
                    font-weight: 600;
                }
            }
        }
    }

    @media only screen and (max-width: 1500px) {
        .content {
            width: 80%;
            height: 80%;
        }
    }

    @media only screen and (max-width: 780px) {

        // .rezervari, html {
        //     height: 85vh;
        // }
        .content {
            background-color: #fff;
            width: 100% !important;
            height: 100% !important;
            border-radius: 0px;
            box-shadow: none;
            margin: 0;
            padding: 0;
            flex-direction: column;
            justify-content: center;

        }
        .left-content, .right-content {
            display: none;
        }
        .center-content {
            width: 100% !important;
            height: 100% !important;
            overflow: hidden;
            padding: 0;
            .header {
                height: 10% !important;
            }
            .main {
                height: 80% !important;
            }
            .footer {
                height: 10% !important;
            }
            .program {
                grid-template-columns: repeat(7, 14%) !important;
                .zi {
                    width: 90% !important;
                }
            }
            .main-ora {
                overflow: hidden;
                overflow-y: scroll;
                height: 84%;
            }

            .footer-ora {
                height: 6%;
            }

            .ore {
                display: flex;
                justify-content: center;
                padding: 0 5px;
                align-items: center;
                .butoane {
                    margin: 0;
                    align-items: center;
                    justify-content: space-evenly !important;
                    margin: 0 auto;
                }
            }
            .titlu-formular {
                .selectat {
                    font-size: 18px !important;

                }
                h1 {
                    font-size: 14px !important;
 

                }
            }

            .formular {
                .rand {
                    flex-direction: column;
                    .coloana {
                        margin: 0;
                        width: 100% !important;
                    }
                    .an {
                        width: 100% !important;
                        .el-date-editor.el-input, .el-date-editor.el-input__inner {
                            width: 100% !important;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .select-container {
            .serviciu {
                h1 {
                    font-size: 20px !important;
                }
                h4 {
                    font-size: 14px !important;
                }
            }

            .pret {
                h1 {
                    font-size: 18px !important;
                }
                h4 {
                    font-size: 12px !important;
                }
            }
        }


    }

    @keyframes fadeIn {
        0% { opacity: 0;}
        100% { opacity: 1;}
    }

    @keyframes FromTop {
        0% {
            margin-top: 20%;
        }

        100% {
            margin-top: 20px;
        }
    }

    @keyframes ToTopOrar {
        0% {
            margin-top: 20%;
        }

        100% {
            margin-top: 0;
        }
    }

    @keyframes grow {
        0% {
            font-size: 14px;
        }


        100% {
            font-size: 16px;
        }
    }
</style>